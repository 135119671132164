import { Routes, Route } from "react-router-dom";
import RootLayout from "./components/RootLayout";
import Dashboard from "./pages/Dashboard";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import logo from "./assets/yolo.png";
import "./App.css";
import About from "./pages/About/About";
import Reservation from "./pages/Reservation";
import Contact from "./pages/Contact";
import Menu from "./pages/Menu";
import Event from "./pages/Events";
import ScrollToTop from "./utils/ScrollToTop";
import Career from "./pages/Career";
import Food from "./pages/Menu/Food";
import Drink from "./pages/Menu/Drinks";
import Product from "./pages/Product";
import Page404 from "./pages/Page404";
import Cart from "./pages/Cart";
import CheckOutForm from "./pages/CheckOutForm";
import Checkout from "./pages/CheckOutPage";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import EventsDetails from "./pages/Events/EventsDetails";
import Ticket from "./pages/Events/Ticket";


function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading process (e.g., fetching data, loading assets)
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000); // Adjust the duration as needed

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <div className="flex flex-col gap-4 items-center justify-center h-screen">
        <motion.img
          src={logo}
          alt="logo"
          className="w-52"
          initial={{ scale: 0.5 }}
          animate={{ scale: 1 }}
          transition={{
            duration: 1.5,
            repeat: Infinity,
          }}
        />
        <p className="text-3xl font-bold">Loading...</p>
      </div>
    );
  }

  return (
    <>
    <ToastContainer position="top-right" theme="dark" />
      <ScrollToTop />{" "}
      <Routes>
        <Route path="/" element={<RootLayout />}>
          <Route index element={<Dashboard />} />
          <Route path="/about" element={<About />} />
          <Route path="/reservation" element={<Reservation />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/menu" element={<Menu />} > 
          <Route index path="/menu/drink" element={<Drink/>}/>
          <Route path="/menu/food" element={<Food/>}/>
          </Route>
          <Route path="/products" element={<Product />} />

          
          <Route path="/events" element={<Event />} />
          <Route path="/events/:_id" element={<EventsDetails />} />
          <Route path="/events/:_id/ticket" element={<Ticket />} />



          <Route path="/cart" element={<Cart />} />
          <Route path="/user-form" element={<CheckOutForm />} />
          <Route path="/checkout" element={<Checkout />} />
          
          <Route path="/career" element={<Career/>} />
          <Route path="*" element={<Page404/>} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
