import React, { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { ProductContext } from "../../context/ProductContext";
import convertTo12Hour from "../../hooks/timeFormatter";

function EventsDetails() {
  const { _id } = useParams();
  const {
    state: { events },
  } = useContext(ProductContext);

  const event = events.find((event) => event._id === _id);

  console.log(event);

  return (
    <div className="min-h-dvh bg-slate-50 flex flex-col  items-center">
      <div
        style={{
          backgroundImage: `url(${event.image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "400px",
        }}
        className="flex items-end justify-center  bg-black/30"
      >
        <div className="md:flex justify-between space-y-5 items-center my-5 backdrop-blur-lg bg-slate-500/20 p-10 w-full md:w-2/3 font-montserrat-alt rounded-lg">
          <p className="text-white font-bold text-2xl">
            {new Date(event.date).toDateString()}
          </p>
          <p className="text-white font-bold text-2xl">
            {convertTo12Hour(event.time)}
          </p>
        </div>
      </div>

      <div className="py-10 md:px-16 px-5 space-y-7">
        <h1 className="font-playfair text-3xl text-center text-amber-700">
          {event.title}
        </h1>
        <p className="md:text-justify text-center md:mx-5 font-montserrat text-lg text-neutral-700">
          {event.description}
        </p>
        <p className="text-center font-bold text-amber-700 text-xl">
          Ticket: &#8358;{event.ticket.toLocaleString()}
        </p>
        <div className="text-center">
          <Link
            to={`/events/${event._id}/ticket`}
            className="bg-amber-600 text-white px-5 py-2 rounded-lg text-center w-full m-auto"
          >
            {event.ticket > 0 ? "Buy Ticket" : "Book Your Spot"}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default EventsDetails;
