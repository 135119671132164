import { useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import yolo from "../assets/yolo.png";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { MdOutlineRestaurantMenu } from "react-icons/md";
import { motion, AnimatePresence } from "framer-motion";

function Navigation() {
  const [showMenu, setShowMenu] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="md:flex items-center justify-between md:px-10 px-5 sticky top-0 z-50 bg-black/40 backdrop-blur-lg">
      <div className="flex justify-between">
        <Link to="/">
          <motion.img
            src={yolo}
            alt="logo"
            className="w-52"
            initial={{ scale: 0.5 }}
            animate={{ scale: 1 }}
            transition={{ duration: 1.5, delay: 0.5 }}
          />
        </Link>
        <button onClick={() => setShowMenu(!showMenu)}>
          {showMenu ? (
            <MdOutlineRestaurantMenu className="text-white text-3xl md:hidden" />
          ) : (
            <HiOutlineMenuAlt3 className="text-white text-3xl md:hidden" />
          )}
        </button>
      </div>
      {/* desktop navigation */}
      <nav className="hidden md:flex items-center">
        <ul
          className={`hidden  md:flex md:space-x-4 items-center font-montserrat `}
        >
          <li>
            <NavLink
              to="/"
              className="text-white transition-all text-sm duration-300 hover:text-purple-400"
            >
              HOME
            </NavLink>
          </li>
          <li
            className="retive p-4"
            onMouseEnter={() => setShowSubMenu(true)}
            onMouseLeave={() => setShowSubMenu(false)}
          >
            <NavLink
              to="/menu/drink"
              className="text-white transition-all text-sm duration-300 hover:text-purple-400"
            >
              MENU
            </NavLink>
            <AnimatePresence>
              {showSubMenu && (
                <motion.ul
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.3 }}
                  className=" absolute mt-2 transform  bg-white border-b-8 transition-all duration-300 border-b-purple-600 shadow-lg space-y-2"
                >
                  <li className="group transition-all duration-300 ease-linear hover:bg-slate-400">
                    {" "}
                    <Link
                      to="/menu/drink"
                      className="text-black transition-all duration-200 p-5 group-hover:text-neutral-700 "
                    >
                      Drink Menu
                    </Link>
                  </li>
                  <li className="group transition-all duration-300 ease-linear hover:bg-slate-400">
                    {" "}
                    <Link
                      to="/menu/food"
                      className="text-black transition-all duration-200 p-5 group-hover:text-neutral-700 "
                    >
                      Food Menu
                    </Link>
                  </li>
                </motion.ul>
              )}
            </AnimatePresence>
          </li>
          <li>
            <NavLink
              to="/reservation"
              className="text-white transition-all text-sm duration-300 hover:text-purple-400"
            >
              RESERVATION
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about"
              className="text-white transition-all text-sm duration-300 hover:text-purple-400"
            >
              ABOUT US
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/events"
              className="text-white transition-all text-sm duration-300 hover:text-purple-400"
            >
              EVENTS
            </NavLink>
          </li>
         
          <li className="">
            <button
              onClick={() => {
                navigate("/contact");
              }}
              className="relative group overflow-hidden text-white border border-white rounded py-3 px-5 transition-all ease-linear duration-300  hover:text-gray-200 "
            >
              <div
                className="absolute -translate-x-36 -z-40 w-36 left-0 h-20 top-0 bg-custom-gradient transition-all
                ease-in-out duration-300 group-hover:translate-x-0"
              ></div>
              CONTACT US
            </button>
          </li>
        </ul>
      </nav>

      {/* mobile navigation */}
      <motion.nav
        className={`h-dvh ${
          showMenu ? "block" : "hidden"
        } md:hidden z-50 fixed top-20 pt-5 left-0 w-full bg-black/85 backdrop-blur-xl px-10`}
        animate={{ y: showMenu ? 0 : "-100%", opacity: showMenu ? 1 : 0 }}
      >
        <ul className="flex flex-col space-y-10 h-full font-montserrat z-50  ">
          <li onClick={() => setShowMenu(false)}>
            <NavLink
              to="/"
              className="text-white py-3 border-b border-b-white hover:text-purple-400"
            >
              HOME
            </NavLink>
          </li>
          <li onClick={() => setShowMenu(false)}>
            <NavLink
              to="/menu/drink"
              className="text-white py-3 border-b border-b-white hover:text-purple-400"
            >
              MENU
            </NavLink>
          </li>
          <li onClick={() => setShowMenu(false)}>
            <NavLink
              to="/reservation"
              className="text-white py-3 border-b border-b-white hover:text-purple-400"
            >
              RESERVATION
            </NavLink>
          </li>
          <li onClick={() => setShowMenu(false)}>
            <NavLink
              to="/about"
              className="text-white py-3 border-b border-b-white hover:text-purple-400"
            >
              ABOUT US
            </NavLink>
          </li>
          <li onClick={() => setShowMenu(false)}>
            <NavLink
              to="/events"
              className="text-white py-3 border-b border-b-white hover:text-purple-400"
            >
              EVENTS
            </NavLink>
          </li>
          
          <li className="text-center w-full">
            <button
              to="/contact"
              onClick={() => {
                setShowMenu(false);
                navigate("/contact");
              }}
              className="text-white border border-white w-full rounded py-3 px-5 transition-all ease-linear duration-300 hover:bg-custom-gradient hover:text-gray-200 hover:border-none"
            >
              CONTACT US
            </button>
          </li>
        </ul>
      </motion.nav>
    </div>
  );
}

export default Navigation;
