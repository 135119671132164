import React, { useContext, useState } from "react";
import { ProductContext } from "../../context/ProductContext";
import ItemCard from "./ItemCard";
import { IoIosSearch } from "react-icons/io";
import { FiLoader } from "react-icons/fi";

function Food() {
  const {
    state: { menuItems },
    loading
  } = useContext(ProductContext);

  const food = menuItems.filter((i) => i.type === "food");
  const [filtered, setFiltered] = useState(food);

  if(loading){
    return <div className="flex justify-center items-center min-h-[500px]">
      <FiLoader className="text-5xl text-center text-purple-600  animate-spin" /> <h2 className="text-3xl text-center text-purple-600">Loading...</h2>
    </div>
  }

  return (
    <div>
      <div className="flex justify-center items-center mx-auto my-5 border rounded-2xl md:w-1/2 px-3">
        <input
          type="text"
          onChange={(e) =>
            setFiltered(
              food.filter((i) => i.name.toLowerCase().includes(e.target.value))
            )
          }
          className="w-full p-2 border-none rounded-l-2xl outline-none"
          placeholder="Search Meal"
        />
        <IoIosSearch className="text-2xl text-gray-600" />
      </div>
      <div className="flex flex-wrap gap-3 justify-center my-10 py-10">
        {filtered.length > 0 ? (
          filtered.map((item) => <ItemCard key={item._id} {...item} />)
        ) : (
          <h3 className="text-amber-600 font-playfair text-3xl text-center">
            No Food Found
          </h3>
        )}
      </div>
    </div>
  );
}

export default Food;
