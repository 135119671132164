// import React, { useContext } from "react";
// import { ProductContext } from "../context/ProductContext";
import { Parallax } from "react-parallax";
import { NavLink } from "react-router-dom";
import { Outlet } from "react-router-dom";

function Menu() {
  // const { state:{menuItems} } = useContext(ProductContext);
 
  // console.log(menuItems);

  

  return (
    <div className="min-h-screen bg-white ">
      <Parallax
        bgImage="/assets/pexels-orlovamaria-4915793.jpg"
        strength={500}
        bgImageStyle={{
          objectFit: "cover",
          objectPosition: "center",
          width: "100%",
          height: "400px",
        }}
      >
        <div className="flex justify-center items-center w-full h-[300px] text-white bg-black/30">
          <h1
            className="font-bold font-playfair bg-custom-gradient bg-clip-text text-transparent text-5xl
          backdrop-filter backdrop-blur-md p-5
          "
          >
            MENU
          </h1>
        </div>
      </Parallax>
      
      <div className="flex items-center justify-center px-5 py-3 rounded-full md:w-1/2 overflow-hidden m-auto text-center font-montecarlo text-xl">
      <NavLink
        to='/menu/drink'
          className={({isActive})=> isActive? "px-5 py-3 bg-custom-gradient text-white w-1/2 rounded-l-full": "bg-white text-purple-600 px-5 py-3 w-1/2 border border-purple-600 rounded-l-full transition-all duration-150 ease-linear"}
          activeClassName="bg-white"
        >Drink</NavLink>
        <NavLink
        to='/menu/food'
        className={({isActive})=> isActive? "px-5 py-3 bg-custom-gradient text-white w-1/2 rounded-r-full": "bg-white text-purple-600 px-5 py-3 w-1/2 border border-purple-600 rounded-r-full transition-all duration-150 ease-linear"}
        >Food</NavLink>
      </div>
      <div className="text-center italic font-playfair">
        <p>
          Download our menu{" "}
          <a
            href="/assets/meal menu.pdf"
            download="Yolo_Lounge_food_menu.pdf"
            className="underline"
          >
            Food
          </a>{" "}
          and{" "}
          <a
            href="/assets/Black Vintage Style Cocktail Menu_20240829_084251_0002.pdf"
            download="Yolo_Lounge_drink_menu.pdf"
            className="underline"
          >
            Drink
          </a>{" "}
        </p>
      </div>
      <div className="my-20 bg-white">
        <Outlet/>
      </div>
    </div>
  );
}

export default Menu;
