import React, { useContext } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ProductContext } from "../context/ProductContext";
import logo from '../assets/yolo.png'
import {PaystackButton} from 'react-paystack'
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Checkout = () => {

    const {state:{cart, buyer}, dispatch} = useContext(ProductContext)
    const history = useNavigate()

    const publicKey = 'pk_test_5718b020c077659d0994e9e9c89c1b7c08678c54'
    const amount = cart.reduce((acc, item) => acc + (item.quantity * item.price), 0) * 100
    const componentProps = {

    email: buyer.email,
    amount: amount,
    metadata: {
      name: buyer.name,
      phone: buyer.phone,
    },
    publicKey,
      text: "Pay Now",
    onSuccess: () =>{
      toast.success("👍Payment Successful") 
      generatePDF()
      dispatch({type: "CLEAR_CART"})
      history('/')

    },
    onClose: () => toast.warning("Wait! Don't leave :("),
    }    

  const generatePDF = () => {
    const doc = new jsPDF();
    //logo
    const logoURL = logo;
    doc.addImage(logoURL, "JPEG", 15, 15, 30, 30);
    // Title
    doc.setFontSize(20);
    doc.text("Yolo Invoice", 150, 20);

    // Customer and Date details
    doc.setFontSize(12);
    doc.text(`Customer: ${buyer.name}`, 20, 45);
    doc.text(`Email: ${buyer.email}`, 20, 50);
    doc.text(`Address: ${buyer.address}`, 20, 60);
    doc.text(`Phone: ${buyer.phone}`, 20, 70);
    doc.text(`Date: ${new Date().toLocaleDateString()}`, 20, 80);

    // Prepare table headers and data
    const headers = [["Item", "Quantity", "Price", "Total"]];
    const data = cart.map((item) => [
      item.name,
      item.quantity,
      `NGN${item.price.toFixed(2)}`,
      `NGN${(item.quantity * item.price).toFixed(2)}`
    ]);

    // Auto table for items
    doc.autoTable({
      startY: 90,
      head: headers,
      body: data,
    });

    // Calculate total price
    const totalPrice = cart.reduce((acc, item) => acc + (item.quantity * item.price), 0);
    doc.text(`Total: NGN${totalPrice.toFixed(2).toLocaleString()}`, 20, doc.lastAutoTable.finalY + 10);

    // Download the generated PDF
    doc.save("Yolo_lounge_invoice.pdf");
  };

  return (
    <div className="h-dvh flex justify-start items-center flex-col font-montserrat-alt text-white space-y-5 px-3">
      <h1 className="text-purple-500 font-bold text-3xl my-5">Checkout</h1>
      <p className="text-xl italic">Thank you for your order, {buyer.name}!</p>
      <p className="text-amber-500 font-bold">
        Please, click the button below to make payment of &#8358;{`${(amount/100).toLocaleString()}`}
      </p>
      <PaystackButton {...componentProps} 
        className="bg-amber-600 text-white px-4 py-2 rounded-lg" 
      />
       
    </div>
  );
};

export default Checkout;
