import React, { useState } from "react";
import { motion } from "framer-motion";
import { containerVariant } from "../utils/animations";
import { MdLocationOn, MdPermPhoneMsg } from "react-icons/md";
import { FaRegEnvelope } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { MdPhone } from "react-icons/md";
import { IoLogoInstagram } from "react-icons/io5";
import emailjs from "emailjs-com";

function Reservation() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    date: "",
    phone: "",
    time: "",
    numberOfGuests: "",
    specialRequests: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const sendReservationEmail = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const dateObject = new Date(formData.date);
      const formattedDate = dateObject.toUTCString(); // You can also use toLocaleString()

      // Include the formatted date in the email data
      const emailData = {
        name: formData.name,
        email: formData.email,
        date: formattedDate,
        time: formData.time,
        phone: formData.phone,
        numberOfGuests: formData.numberOfGuests,
        specialRequests: formData.specialRequests,
      };

      const response = await emailjs.send(
        "service_3ixqpek",
        "template_yuie15b",
        emailData,
        "TE_pd5r_6D6XKVVXp"
      );

      console.log("Reservation email sent:", response.status, response.text);

      setLoading(false);
      setFormData({
        name: "",
        email: "",
        date: "",
        phone: "",
        time: "",
        numberOfGuests: "",
        specialRequests: "",
      });
    } catch (error) {
      setLoading(false)
      setError("Failed to send reservation email");
      console.error(error);
    }
  };
  return (
    <>
      <motion.div
        initial={{ opacity: 0, x: "-100vw" }}
        animate={{ opacity: 1, x: 0 }}
        className="flex justify-center space-x-5 font-mono bg-white py-3 flex-wrap"
      >
        <motion.a
          whileHover={{
            scale: 1.1,
            rotate: [0, -5, 5, -5, 5, 0], // Twist motion
            transition: {
              duration: 0.5, // Duration of the shake
              ease: "easeInOut", // Smooth the animation
              repeatType: "mirror", // Mirror the animation on repeat
            },
          }}
          href="https://wa.me/2348066228063?text=I%20want%20to%20make%20a%20reservation"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaWhatsapp className="text-3xl" />
        </motion.a>

        <motion.a
          whileHover={{
            scale: 1.1,
            rotate: [0, -5, 5, -5, 5, 0], // Twist motion
            transition: {
              duration: 0.5, // Duration of the shake
              ease: "easeInOut", // Smooth the animation
              repeatType: "mirror", // Mirror the animation on repeat
            },
          }}
          href="mailto:Yololounge0@gmail.com?subject=Reservation%20Line&body=This%20is%20the%20body%20of%20the%20email"
          target="_blank"
          rel="noopener noreferrer"
        >
          <HiOutlineMail className="text-3xl" />
        </motion.a>

        <motion.a
          whileHover={{
            scale: 1.1,
            rotate: [0, -5, 5, -5, 5, 0], // Twist motion
            transition: {
              duration: 0.5, // Duration of the shake
              ease: "easeInOut", // Smooth the animation
              repeatType: "mirror", // Mirror the animation on repeat
            },
          }}
          href="tel:+2348066228063"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center space-x-1"
        >
          <MdPhone className="text-3xl" />
          <span>+2348066228063</span>
        </motion.a>

        <motion.a
          whileHover={{
            scale: 1.1,
            rotate: [0, -5, 5, -5, 5, 0], // Twist motion
            transition: {
              duration: 0.5, // Duration of the shake
              ease: "easeInOut", // Smooth the animation
              repeatType: "mirror", // Mirror the animation on repeat
            },
          }}
          href="tel:+2349127256466"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center space-x-1"
        >
          <MdPhone className="text-3xl" />
          <span>+2349127256466</span>
        </motion.a>

        <motion.a
          whileHover={{
            scale: 1.1,
            rotate: [0, -5, 5, -5, 5, 0], // Twist motion
            transition: {
              duration: 0.5, // Duration of the shake
              ease: "easeInOut", // Smooth the animation
              repeatType: "mirror", // Mirror the animation on repeat
            },
          }}
          href="https://www.instagram.com/Yolo_lounge_restaurant"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center"
        >
          <IoLogoInstagram className="text-3xl" />
        </motion.a>
      </motion.div>
      <div className="min-h-screen bg-white flex flex-col-reverse md:flex-row gap-3 items-center justify-center">
        <motion.div
          variants={containerVariant}
          initial="hidden"
          animate="visible"
          className="flex flex-col items-center justify-center h-screen"
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            className="border border-purple-500 rounded-xl p-5"
          >
            <h2 className="text-3xl font-bold font-montecarlo text-center mb-5 text-neutral-400">
              Make reservation
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
              <input
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                type="text"
                placeholder="John Doe"
                className="border border-purple-500 rounded-md p-2 outline-none"
              />
              <input
                name="email"
                type="email"
                placeholder="example@gmail.com"
                value={formData.email}
                onChange={handleChange}
                required
                className="border border-purple-500 rounded-md p-2 outline-none"
              />
              <input
                type="phone"
                name="phone"
                placeholder="Phone Number"
                pattern="[0-9]{11}"
                value={formData.phone}
                onChange={handleChange}
                required
                className="border border-purple-500 rounded-md p-2 outline-none"
              />
              <input
                type="date"
                name="date"
                placeholder="Date"
                value={formData.date}
                onChange={handleChange}
                required
                className="border border-purple-500 rounded-md p-2 outline-none"
              />
              <input
                type="time"
                name="time"
                placeholder="Time"
                value={formData.time}
                onChange={handleChange}
                required
                className="border border-purple-500 rounded-md p-2 outline-none"
              />
              <input
                type="number"
                name="numberOfGuests"
                placeholder="Number of Guests"
                value={formData.numberOfGuests}
                onChange={handleChange}
                required
                className="border border-purple-500 rounded-md p-2 outline-none"
              />
              <textarea
                name="specialRequests"
                value={formData.specialRequests}
                onChange={handleChange}
                id="specialRequests"
                cols="30"
                rows="10"
                className="border border-purple-500 rounded-md p-2 outline-none md:col-span-2"
                placeholder="Please tell us any special requests you have"
              ></textarea>

              <motion.button
                type="submit"
                className="bg-purple-500 text-white p-2 rounded-md md:col-span-2 disabled:cursor-not-allowed"
                whileHover={{ scale: 1.05, backgroundColor: "#6B46C1" }}
                whileTap={{ scale: 0.9 }}
                transition={{ duration: 0.2 }}
                onClick={sendReservationEmail}
                disabled={loading}
              >
                {loading? "Sending..." : "Reserve"}
              </motion.button>
            </div>
            {error && (<div className="p-4 text-red-500 border-2 rounded-lg border-red-500 my-3">{error}</div>)}
          </form>
        </motion.div>
        <motion.div
          variants={containerVariant}
          initial="hidden"
          animate="visible"
          transition={{ delay: 0.5 }}
          className="border-r p-10 rounded-lg shadow-xl space-y-4 mx-2 h-[500px]"
        >
          <h2 className="text-neutral-700 font-playfair font-bold text-2xl text-center mb-3">
            Reach out to us
          </h2>
          <div className="m-2  space-y-2 ">
            <h3 className="flex gap-1 items-center text-purple-700 font-montserrat text-xl">
              {" "}
              <MdLocationOn /> Address
            </h3>
            <p className="font-montserrat-alt">
              Greenland Estate, Obasanjo road, Ota, Ogun State
            </p>
          </div>

          <div className="m-2 space-y-2 ">
            <h3 className="flex gap-1 items-center text-purple-700 font-montserrat text-xl">
              {" "}
              <MdPermPhoneMsg /> Phone:
            </h3>
            <p className="font-montserrat-alt">+2348123456789</p>
          </div>

          <div className="m-2 space-y-2 ">
            <h3 className="flex gap-1 items-center text-purple-700 font-montserrat text-xl">
              {" "}
              <FaRegEnvelope /> Email:
            </h3>
            <p className="font-montserrat-alt">yololounge0@gmail.com</p>
          </div>
        </motion.div>
      </div>
    </>
  );
}

export default Reservation;
