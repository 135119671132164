import React, { useContext } from "react";
import Navigation from "./Navigation";
import { Outlet, Link } from "react-router-dom";
import Footer from "./Footer";
import { ProductContext } from "../context/ProductContext";
import { FaOpencart } from "react-icons/fa6";

function RootLayout() {
  const {
    state: { cart },
  } = useContext(ProductContext);

  return (
    <div className="bg-slate-950 relative">
      <Navigation />

      <Outlet />

      {cart.length > 0 && (
        <Link
        to='/cart'
        className="grid place-content-center border-2 border-purple-600 fixed bottom-5 right-3 bg-white  w-14 h-14 p-2 rounded-full text-purple-600 transition-all ease-linear duration-200 hover:bg-purple-600 hover:text-white">
          <p className="text-xs bg-purple-500 text-white absolute left-0 p-1 rounded-full font-montserrat-alt h-5 w-5 grid place-content-center">
            {cart.length}
          </p>{" "}
          <FaOpencart className="text-2xl " />
        </Link>
      )}

      <Footer />
    </div>
  );
}

export default RootLayout;
