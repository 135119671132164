import React, { useContext, useState } from "react";
import { ProductContext } from "../../context/ProductContext";
import ItemCard from "./ItemCard";
import ReactPaginate from "react-paginate";
import { IoIosSearch } from "react-icons/io";
import { FiLoader } from "react-icons/fi";


function Drink() {
  const {
    state: { menuItems },
    loading
  } = useContext(ProductContext);

  const drink = menuItems.filter((i) => i.type === "drink");

  const itemsPerPage = 6;

  // Track current page
  const [currentPage, setCurrentPage] = useState(0);
  const [filteredDrink, setFilteredDrink] = useState(drink);

  // Calculate the total number of pages
  const pageCount = Math.ceil(filteredDrink.length / itemsPerPage);

  // Determine which drink to display based on the current page
  const currentItems = filteredDrink.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  // Handle page change
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  if(loading){
    return <div className="flex justify-center items-center min-h-[500px]">
      <FiLoader className="text-5xl text-center text-purple-600  animate-spin" /> <h2 className="text-3xl text-center text-purple-600">Loading...</h2>
    </div>
  }


  return (
    <div className="bg-white">
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        containerClassName={"pagination flex justify-center mt-4 space-x-2"}
        pageClassName={"page-item"}
        pageLinkClassName={"px-3 py-1 border rounded-md overflow-hidden"}
        previousLinkClassName={"px-3 py-1 border rounded-full"}
        nextLinkClassName={"px-3 py-1 border rounded-full"}
        activeClassName={"bg-custom-gradient text-white "}
        disabledClassName={"opacity-50 cursor-not-allowed"}
      />
      <div className="flex justify-center items-center mx-auto my-5 border rounded-2xl md:w-1/2 px-3">
        <input
          type="text"
          onChange={(e) =>
            setFilteredDrink(
              drink.filter((i) => i.name.toLowerCase().includes(e.target.value))
            )
          }
          className="w-full p-2 border-none rounded-l-2xl outline-none"
          placeholder="Search Drink"
        />
        <IoIosSearch className="text-2xl text-gray-600" />
      </div>
      <div className="flex flex-wrap justify-center gap-2 items-center m-5 py-10 ">
        {filteredDrink.length > 0 ? (
          currentItems.map((item) => <ItemCard key={item._id} {...item} />)
        ) : (
          <p className="text-amber-600 font-playfair text-3xl text-center">No item found</p>
        )}
      </div>
    </div>
  );
}

export default Drink;
