import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { FaWhatsapp } from "react-icons/fa";


function Footer() {
  return (
    <footer className="bg-black font-montserrat">
      <div className="mx-auto max-w-screen-xl px-4 pb-8 pt-16 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-md">
          <strong className="block text-center text-xl font-bold text-gray-100 sm:text-3xl">
            Want us to email you with the latest event and updates?
          </strong>

          <form className="mt-6">
            <div className="relative max-w-lg">
              <label className="sr-only" htmlFor="email">
                {" "}
                Email{" "}
              </label>

              <input
                className="w-full rounded-full border-gray-200 bg-gray-100 p-4 pe-32 text-sm font-medium"
                id="email"
                type="email"
                placeholder="john@doe.com"
              />

              <button className="absolute end-1 top-1/2 -translate-y-1/2 rounded-full bg-purple-600 px-5 py-3 text-sm font-medium text-white transition hover:bg-blue-700">
                Subscribe
              </button>
            </div>
          </form>
        </div>

        <div className="mt-16 grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-32">
          <div className="mx-auto max-w-sm lg:max-w-none">
            <Link to='/'>
            <motion.img
              src="/assets/yolo.png"
              width={100}
              alt="Yolo logo"
              initial={{ scale: 0.9 }}
              animate={{ scale: [1, 1.1, 1.05, 0.9, 1] }}
              transition={{
                duration: 0.5,
                repeat: Infinity,
                repeatType: "reverse",
              }}
            />
            </Link>
            <p className="mt-4 text-center text-gray-200 lg:text-left lg:text-lg">
              Join us for a taste of Naija hospitality. From mouthwatering
              dishes to lively vibes, we've got you covered.
            </p>

            <div className="mt-6 flex justify-center gap-4 lg:justify-start">
              

              <a
                className="text-white transition hover:text-gray-100/75"
                href="https://www.instagram.com/Yolo_lounge_restaurant"
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only"> Instagram </span>

                <svg
                  className="size-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>

              

              

              <a
                className="text-white transition hover:text-gray-100/75"
                href="https://wa.me/2348066228063?text=I%20want%20to%20make%20a%20reservation"
                target="_blank"
                rel="noreferrer"
              >
                <FaWhatsapp size={24} />
              </a>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-8 text-center lg:grid-cols-3 lg:text-left">
            <div>
              <strong className="font-medium text-white"> Services </strong>

              <ul className="mt-6 space-y-1">
                <li>
                  <Link
                    className="text-gray-200 transition hover:text-purple-500/75"
                    href="#"
                  >
                    {" "}
                    Product{" "}
                  </Link>
                </li>

                <li>
                  <Link
                    className="text-gray-200 transition hover:text-purple-500/75"
                    to='/reservation'
                  >
                    Lounge Services
                  </Link>
                </li>
              </ul>
            </div>

            <div>
              <strong className="font-medium text-white"> About </strong>

              <ul className="mt-6 space-y-1">
                <li>
                  <Link
                    className="text-gray-200 transition hover:text-purple-500/75"
                    to='/about'
                  >
                    {" "}
                    About{" "}
                  </Link>
                </li>

                <li>
                  <Link
                    className="text-gray-200 transition hover:text-purple-500/75"
                    to = '/career'
                  >
                    {" "}
                    Careers{" "}
                  </Link>
                </li>

                <li>
                  <Link
                    className="text-gray-200 transition hover:text-purple-500/75"
                    href="#"
                  >
                    {" "}
                    History{" "}
                  </Link>
                </li>

                <li>
                  <Link
                    className="text-gray-200 transition hover:text-purple-500/75"
                    href="#"
                  >
                    {" "}
                    Our Team{" "}
                  </Link>
                </li>
              </ul>
            </div>

            <div>
              <strong className="font-medium text-white"> Support </strong>

              <ul className="mt-6 space-y-1">
                <li>
                  <Link
                    className="text-gray-200 transition hover:text-purple-500/75"
                    to='/contact'
                  >
                    {" "}
                    FAQs{" "}
                  </Link>
                </li>

                <li>
                  <Link
                    className="text-gray-200 transition hover:text-purple-500/75"
                    to='/contact'
                  >
                    {" "}
                    Contact{" "}
                  </Link>
                </li>

                <li>
                  <Link
                    className="text-gray-200 transition hover:text-purple-700/75"
                    content="/reservation"
                  >
                    {" "}
                    Live Chat{" "}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="mt-16 border-t border-gray-100 pt-8">
          <p className="text-center text-xs/relaxed text-gray-200">
            © Company 2024. All rights reserved.
            <br />
            Created with 
            <Link
              href="#"
              className="text-gray-200 underline transition hover:text-gray-200/75"
            >
              React
            </Link> 
            and
            <Link
              href="#"
              className="text-gray-200 underline transition hover:text-gray-200/75"
            > 
              Express JS
            </Link>
            .
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
