import { createContext, useEffect, useReducer, useState } from "react";
import axios from "axios";

export const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const initialState = {
    products: [],
    menuItems: [],
    events: [],
    cart: [],
    buyer: [],
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "FETCH_PRODUCTS":
        return { ...state, products: action.payload };
      case "FETCH_MENU":
        return { ...state, menuItems: action.payload };
      case "FETCH_EVENTS":
        return { ...state, events: action.payload };

      case "ADD_TO_CART":
        return {
          ...state,
          cart: [...state.cart, { ...action.payload, quantity: 1 }],
        };

      case "REMOVE_FROM_CART":
        return {
          ...state,
          cart: state.cart.filter((item) => item._id !== action.payload),
        };

      case "CHANGE_QUANTITY":
        return {
          ...state,
          cart: state.cart
            .map((item) =>
              item._id === action.payload._id
                ? { ...item, quantity: action.payload.quantity }
                : item
            )
            .filter((item) => item.quantity > 0),
        };

      case "ADD_BUYER":
        return { ...state, buyer: action.payload };
      case "CLEAR_CART":
        return { ...state, cart: [], buyer: [] };

      default:
        return state;
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          "https://yolo-server-bu7h.onrender.com/api/products"
        );
        dispatch({ type: "FETCH_PRODUCTS", payload: response.data });
        // console.log("product",response.data);
      } catch (error) {
        console.error(error);

      }
    };
    const fetchMenuItems = async () => {
      try {
        setLoading(true);

        const response = await axios.get(
          "https://yolo-server-bu7h.onrender.com/api/menu"
        );
        const res = await response.data;
        dispatch({ type: "FETCH_MENU", payload: res });
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    const fetchEvents = async () => {
      try {
        const response = await axios.get(
          "https://yolo-server-bu7h.onrender.com/api/event"
        );
        dispatch({ type: "FETCH_EVENTS", payload: response.data });
      } catch (error) {
        console.error(error);
      }
    };
    fetchMenuItems();
    fetchProducts();
    fetchEvents();
    // const cart = window.localStorage.getItem("cart");
    // if (cart) {
    // dispatch({ type: "ADD_TO_CART", payload: JSON.parse(cart) });
    // }
  }, []);

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ProductContext.Provider value={{ state, dispatch, loading }}>
      {children}
    </ProductContext.Provider>
  );
};
