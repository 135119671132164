import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { ProductContext } from "../../context/ProductContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { PaystackButton } from "react-paystack";
import jsPDF from "jspdf";
import "jspdf-autotable";

function Ticket() {
  const { _id } = useParams();
  const {
    state: { events },
  } = useContext(ProductContext);
  const event = events.find((event) => event._id === _id);

  const history = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [quantity, setQuantity] = useState(1);
  const ticketId = Math.floor(Math.random() * 1000000000);

  const publicKey = "pk_test_5718b020c077659d0994e9e9c89c1b7c08678c54";
  const amount = event.ticket * quantity * 100;

  const componentProps = {
    email,
    amount,
    metadata: {
      name,
      phone,
      event: event.title,
    },
    publicKey,
    text: "Buy Ticket",
    onSuccess: () => {
      try {
        toast.success("👍You got your ticket");
        generatePDF();

        history("/");
      } catch (err) {
        toast.error("An error occurred");
      }
    },
    onClose: () => {
      try {
        toast.warning("Wait! Don't leave :(");
      } catch (err) {
        toast.error("An error occurred");
      }
    },
  };
  const bookSpot = () => {
    toast.success("Spot booked successfully");
    generatePDF();
    history("/");
  };

  const generatePDF = () => {
    const doc = new jsPDF();

    // Add title
    doc.setFontSize(22);
    doc.text("Event Ticket", 105, 20, null, null, "center");

    // Event title and details
    doc.setFontSize(16);
    doc.text(`Event: ${event.title}`, 20, 40);
    doc.text(`Date: ${new Date(event.date)}`, 20, 50);

    // Ticket ID
    const ticketID = ticketId;

    // Buyer Details
    doc.text(`Buyer: ${name}`, 20, 80);

    // Ticket Amount and Quantity
    doc.setFontSize(14);
    doc.text(`Quantity: ${quantity}`, 20, 100);
    doc.text(`Amount Paid: ₦${event.ticket.toFixed(2)}`, 20, 110);

    // Add a simple divider
    doc.setDrawColor(0);
    doc.line(20, 120, 190, 120);

    // Footer note
    doc.setFontSize(10);
    doc.text("Thank you for your purchase!", 105, 140, null, null, "center");
    doc.text(
      "Please bring this ticket to the event entrance.",
      105,
      150,
      null,
      null,
      "center"
    );

    // Save or return the PDF
    doc.save(`Ticket-${ticketID}.pdf`);
  };

  return (
    <div className=" flex items-center justify-center min-h-dvh px-5">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (quantity < 1) {
            toast.error("Quantity cannot be less than 1");
            setQuantity(1);
            return;
          }
        }}
        className=" px-2 py-5 md:py-14 border w-full border-amber-300 rounded-lg md:px-20 text-slate-800 font-montserrat md:w-2/3 space-y-3"
      >
        <div className="grid grid-cols-1 md:grid-cols-3 text-center">
          <label
            htmlFor="name"
            className="col-span-1  text-2xl font-montserrat-alt font-extrabold text-white"
          >
            Name:
          </label>
          <input
            type="text"
            name="name"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="col-span-1 md:col-span-2 w-full px-2 py-1 rounded-lg outline-none"
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 text-center">
          <label
            htmlFor="email"
            className="col-span-1  text-2xl font-montserrat-alt font-extrabold text-white"
          >
            Email:
          </label>
          <input
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="col-span-1 md:col-span-2 w-full px-2 py-1 rounded-lg outline-none"
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 text-center">
          <label
            htmlFor="phone"
            className="col-span-1  text-2xl font-montserrat-alt font-extrabold text-white"
          >
            Phone:
          </label>
          <input
            type="tel"
            name="phone"
            id="phone"
            value={phone}
            required
            onChange={(e) => setPhone(e.target.value)}
            className="col-span-1 md:col-span-2 w-full px-2 py-1 rounded-lg outline-none"
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 text-center">
          <label
            htmlFor="quantity"
            className="col-span-1  text-2xl font-montserrat-alt font-extrabold text-white"
          >
            Quantity:
          </label>
          <input
            type="number"
            name="quantity"
            id="quantity"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            required
            pattern="^[1-9][0-9]*$"
            title="Quantity must be greater than 0"
            className="col-span-1 md:col-span-2 w-full px-2 py-1 rounded-lg outline-none"
          />
        </div>
        <div>
          <p className="text-xl font-montserrat text-white">
            Total: &#8358;{(event.ticket * quantity).toLocaleString()}
          </p>{" "}
          {event.ticket > 0 ? (
            quantity > 0 && (
              <PaystackButton
                {...componentProps}
                className="bg-amber-600 text-white px-4 py-2 rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
              />
            )
          ) : (
            <button
              className="bg-amber-600 text-white px-4 py-2 rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={quantity < 1}
              onClick={bookSpot}
            >
              {" "}
              Book your spot
            </button>
          )}
        </div>
      </form>
    </div>
  );
}

export default Ticket;
